import NetResponseModel from "./NetResponseModel";

export default class CounterpartyNetRequestModel {
  constructor(
    public netsByPeriod: Array<CounterpartyNetByPeriodModel> = [
      new CounterpartyNetByPeriodModel(),
    ],
    public counterpartyId?: number
  ) {}

  static ofRequest(
    model: CounterpartyNetRequestModel
  ): CounterpartyNetRequestModel {
    return new CounterpartyNetRequestModel(
      CounterpartyNetByPeriodModel.ofRequest(model.netsByPeriod),
      model.counterpartyId
    );
  }
}

export class CounterpartyNetByPeriodModel {
  constructor(
    public dateFrom: string | Date | null = null,
    public dateTo: string | Date | null = null,
    public net: number = 30,
    public isDateFromMenuActive?: boolean,
    public isDateToMenuActive?: boolean
  ) {}

  static ofArray(
    items: Array<NetResponseModel | CounterpartyNetByPeriodModel>
  ): Array<CounterpartyNetByPeriodModel> {
    return items.map(
      (item) =>
        new CounterpartyNetByPeriodModel(item.dateFrom, item.dateTo, item.net)
    );
  }

  static ofRequest(
    items: Array<CounterpartyNetByPeriodModel>
  ): Array<CounterpartyNetByPeriodModel> {
    return items.map(
      (item) =>
        new CounterpartyNetByPeriodModel(item.dateFrom, item.dateTo, item.net)
    );
  }
}
