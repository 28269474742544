























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import { CounterpartyNetByPeriodModel } from "@/accounting-portal/models/counterparties/NetRequestModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import DateUtil from "@/shared/utils/DateUtil";

@Component
export default class NetRevenueForm extends Vue {
  @Prop() value!: Array<CounterpartyNetByPeriodModel>;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get localValue(): Array<CounterpartyNetByPeriodModel> {
    return this.value;
  }

  set localValue(value: Array<CounterpartyNetByPeriodModel>) {
    this.$emit("input", value);
  }

  getMinDateForDateFrom(index: number): string {
    return !index
      ? "2018-09-01"
      : DateUtil.formatForDatePicker(
          moment(this.localValue[index - 1].dateTo).add(1, "days")
        );
  }

  getMaxDateForDateFrom(index: number): string {
    return !index
      ? ""
      : DateUtil.formatForDatePicker(
          moment(this.localValue[index - 1].dateTo).add(1, "days")
        );
  }

  getMinDateForDateToByIndex(index: number): string {
    return !index
      ? "2018-09-01"
      : DateUtil.formatForDatePicker(
          moment(this.localValue[index].dateFrom).add(1, "days")
        );
  }

  getErrorMessagesForDateFrom(index: number): Array<string> {
    if (
      this.localValue.length > 1 &&
      index &&
      this.localValue[index].dateFrom &&
      DateUtil.formatForDatePicker(
        moment(this.localValue[index - 1].dateTo).add(1, "days")
      ) !== (this.localValue[index].dateFrom as any)
    ) {
      return [
        this.$lang(
          "accountingPortal.counterparties.netRevenue.dateOneDayGreater"
        ),
      ];
    }

    return [];
  }

  getErrorMessagesForDateTo(index: number): Array<string> {
    if (this.localValue.length > 1 && index !== this.localValue.length - 1) {
      if (!this.localValue[index].dateTo) {
        return [this.$lang("validation.required")];
      }

      if (
        DateUtil.isSameOrBefore(
          this.localValue[index].dateTo as string,
          this.localValue[index].dateFrom as string
        )
      ) {
        return [
          this.$lang(
            "accountingPortal.counterparties.netRevenue.minDateTo",
            this.localValue[index].dateFrom as string
          ),
        ];
      }
    }

    return [];
  }

  async handleDeleteItemByIndex(deletingIndex: number) {
    this.localValue = this.localValue.filter(
      (_: any, index: number) => index !== deletingIndex
    );
    await this.$nextTick();
    this.localValue[this.localValue.length - 1].dateTo = null;
  }
}
