













































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import EntityCard from "@/accounting-portal/components/counterparties/EntityCard.vue";
import NetRevenueTable from "@/accounting-portal/components/counterparties/net-revenue/NetRevenueTable.vue";
import NetRevenueForm from "@/accounting-portal/components/counterparties/net-revenue/NetRevenueForm.vue";
import NetRevenueResponseModel from "@/settings/models/networks-management/NetRevenueResponseModel";
import CounterpartyNetRequestModel, {
  CounterpartyNetByPeriodModel,
} from "@/accounting-portal/models/counterparties/NetRequestModel";
import { AppSection } from "@/shared/models";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: {
    EntityCard,
    NetRevenueTable,
    NetRevenueForm,
  },
})
export default class NetRevenueView extends mixins(AppSectionAccessMixin) {
  visible = false;
  localValue = new CounterpartyNetRequestModel();

  get saving(): boolean {
    return this.$store.state.counterpartyStore.isNetRevenuesSaving;
  }

  get netRevenues(): Array<NetRevenueResponseModel> {
    return this.$store.state.counterpartyStore.netRevenues;
  }

  get counterpartyId(): number {
    return Number(this.$route.params.counterpartyId);
  }

  get appSection(): AppSection {
    return AppSection.COUNTERPARTIES;
  }

  async created() {
    await this.$store.dispatch(
      "loadCounterpartyNetRevenues",
      this.counterpartyId
    );

    this.localValue.netsByPeriod = CounterpartyNetByPeriodModel.ofArray(
      this.netRevenues
    );
  }

  handleChangeVisible(value: boolean) {
    this.visible = value;

    if (value && !this.netRevenues.length) {
      this.localValue.netsByPeriod = [
        new CounterpartyNetByPeriodModel("2018-09-01"),
      ];
    }
  }

  handleAddingPeriod() {
    this.localValue.netsByPeriod.push(new CounterpartyNetByPeriodModel());
  }

  async handleSaving() {
    if (this.netRevenues.length) {
      await this.$store.dispatch("updateCounterpartyNetRevenue", {
        counterpartyId: this.counterpartyId,
        payload: CounterpartyNetRequestModel.ofRequest(this.localValue),
      });
    } else {
      this.localValue.counterpartyId = this.counterpartyId;
      await this.$store.dispatch(
        "createCounterpartyNetRevenue",
        CounterpartyNetRequestModel.ofRequest(this.localValue)
      );
    }

    this.handleChangeVisible(false);
  }

  handleDeletingAll() {
    this.$store.dispatch(
      "deleteAllCounterpartyNetRevenues",
      this.counterpartyId
    );
  }
}
