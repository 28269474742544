










import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import NetResponseModel from "@/accounting-portal/models/counterparties/NetResponseModel";
import { AppSection } from "@/shared/models";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component
export default class NetRevenueTable extends mixins(AppSectionAccessMixin) {
  readonly HEADERS = [
    {
      text: this.$lang("accountingPortal.counterparties.netRevenue.dateFrom"),
      value: "dateFrom",
    },
    {
      text: this.$lang("accountingPortal.counterparties.netRevenue.dateTo"),
      value: "dateTo",
    },
    {
      text: this.$lang("accountingPortal.counterparties.netRevenue.net"),
      value: "net",
    },
  ];

  get loading(): boolean {
    return this.$store.state.counterpartyStore.isNetRevenuesLoading;
  }

  get netRevenues(): Array<NetResponseModel> {
    return this.$store.state.counterpartyStore.netRevenues;
  }

  get appSection(): AppSection {
    return AppSection.COUNTERPARTIES;
  }

  emitDeleteAll() {
    this.$emit("deleteAll");
  }
}
